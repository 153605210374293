import React, { useState } from "react";
import "./SocialSlider.css";

const socialIcons = {
  Instagram:
    "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1736767984881.png",
  Facebook:
    "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1736767951243.png",
  LinkedIn:
    "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1736768023447.png",
  YouTube:
    "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1736768060374.png",
};

const data = {
  Instagram: [
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737356114291.jpg",
      description:
        "Day 3 is all about the BYD SEALION 7, the Pure Performance eSUV. The excitement is palpable as visitors experience its ocean-inspired design and cutting-edge technology up close. A truly phenomenal moment at the Bharat Mobility Global Expo 2025. Visit us at Hall No. 6, Pavilion No. 8.",
      link: "https://www.instagram.com/p/DFASZbRyaEO",
    },
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737355992897.jpg",
      description:
        "6, 7, and 8 have been the talk of the town and rightly so. With groundbreaking technology and unmatched performance, the BYD lineup—BYD SEALION 6, BYD SEALION 7, and YANGWANG U8—turned heads at the Bharat Mobility Global Expo 2025.",
      link: "https://www.instagram.com/p/DE-FY4FSxKf",
    },
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737355946209.jpg",
      description:
        "Day 2 kicked off with unmatched energy and enthusiasm! The newly launched BYD SEALION 7 stole the spotlight, with media experts admiring its ocean-inspired design. The crowd also had the chance to interact with the team and explore the full BYD lineup. As we wrap up today, we can’t wait to welcome you tomorrow at Hall 6, Pavilion 8!",
    },
  ],
  Facebook: [
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737356097650.jpg",
      link: "https://www.facebook.com/share/p/18uAJAN9kD",
      description:
        "Day 3 is all about the BYD SEALION 7, the Pure Performance eSUV. The excitement is palpable as visitors experience its ocean-inspired design and cutting-edge technology up close. A truly phenomenal moment at the Bharat Mobility Global Expo 2025. Visit us at Hall No. 6, Pavilion No. 8.",
    },
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737356004381.jpg",
      link: "https://www.facebook.com/share/p/14gTEsduMg/",
      description:
        "6, 7, and 8 have been the talk of the town and rightly so. With groundbreaking technology and unmatched performance, the BYD lineup—BYD SEALION 6, BYD SEALION 7, and YANGWANG U8—turned heads at the Bharat Mobility Global Expo 2025.",
    },
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737355958113.jpg",
      link: "https://www.facebook.com/share/p/1Bw9xxdk8j/",
      description:
        "Day 2 kicked off with unmatched energy and enthusiasm! The newly launched BYD SEALION 7 stole the spotlight, with media experts admiring its ocean-inspired design. The crowd also had the chance to interact with the team and explore the full BYD lineup. As we wrap up today, we can’t wait to welcome you tomorrow at Hall 6, Pavilion 8!",
    },
  ],
  LinkedIn: [
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737356083562.jpg",
      link: "https://www.linkedin.com/posts/byd-india-private-limited_bharat-mobility-global-expo-2025-day-3-activity-7286714596567007233-b36J",
      description:
        "Day 3 is all about the BYD SEALION 7, the Pure Performance eSUV. The excitement is palpable as visitors experience its ocean-inspired design and cutting-edge technology up close. A truly phenomenal moment at the Bharat Mobility Global Expo 2025.",
    },
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737356015964.jpg",
      link: "https://www.linkedin.com/posts/byd-india-private-limited_bharat-mobility-global-expo-2025-activity-7286387102286561281-oab7",
      description:
        "6, 7, and 8 have been the talk of the town and rightly so. With groundbreaking technology and unmatched performance, the BYD lineup—BYD SEALION 6, BYD SEALION 7, and YANGWANG U8—turned heads at the Bharat Mobility Global Expo 2025.",
    },
    {
      image:
        "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737355970192.jpg",
      link: "https://www.linkedin.com/posts/byd-india-private-limited_bharat-mobility-global-expo-2025-day-2-activity-7286386454870573057-wwck",
      description:
        "Day 2 kicked off with unmatched energy and enthusiasm! The newly launched BYD SEALION 7 stole the spotlight, with media experts admiring its ocean-inspired design. The crowd also had the chance to interact with the team and explore the full BYD lineup. As we wrap up today, we can’t wait to welcome you tomorrow at Hall 6, Pavilion 8!",
    },
  ],
  // YouTube: [
  //   {
  //     image: "https://swiperjs.com/demos/images/nature-3.jpg",
  //     title: "YouTube Video 1",
  //     designation: "Video Content",
  //     description: "Watch our latest video on sustainable mobility!",
  //   },
  //   {
  //     image: "https://swiperjs.com/demos/images/nature-4.jpg",
  //     title: "YouTube Video 2",
  //     designation: "Auto Expo",
  //     description: "Catch the highlights of the Auto Expo on YouTube.",
  //   },
  // ],
};

const SocialSlider = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("Instagram");

  return (
    <div className="carousel-container-slide" style={{}}>
      <h2 className="carousel-title">Follow us</h2>
      <div className="slider-box">
        <div className="social-buttons">
          {Object.keys(data).map((platform) => (
            <p
              key={platform}
              className={`social-button ${
                selectedPlatform === platform ? "active" : ""
              }`}
              onClick={() => setSelectedPlatform(platform)}
            >
              {platform}
            </p>
          ))}
        </div>
        <div className="grid-container">
          {data[selectedPlatform].map((item, index) => (
            <div key={index} className="card">
              <a href={item?.link} target="_blank">
                <img src={item.image} alt={item.title} className="card-image" />

                <img
                  src={socialIcons[selectedPlatform]}
                  alt={`${selectedPlatform} icon`}
                  className="social-icon"
                />

                <div className="card-content">
                  <p className="card-description">
                    {item.description.split(" ").slice(0, 40).join(" ")}
                    {item.description.split(" ").length > 40 ? "..." : ""}
                  </p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SocialSlider;
