import React, { useEffect, useState } from "react";
import "./ImageGallery.css";

const ImageGallery = () => {
  const mediaItems = [
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737516966323.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737516951719.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737516934099.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737516920587.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737516908291.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737516892972.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737516870866.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737357292565.jpg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737357363023.jpg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737357383282.jpg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737173637158.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737174234195.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737174253362.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737174270154.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737174299223.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737174314127.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737174678009.jpeg",
    },
    {
      type: "image",
      url: "https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1737174663980.jpeg",
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4); 

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth > 1024) {
        setItemsPerPage(4);
      } else if (window.innerWidth > 500) {
        setItemsPerPage(2); 
      } else {
        setItemsPerPage(1); 
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = mediaItems.slice(startIndex, endIndex);

  const handlePrev = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (endIndex < mediaItems.length) setCurrentPage(currentPage + 1);
  };

  return (
    <div className="gallery-container">
      <div className="gallery-container-inner">
        <div className="gallery-info">
          <h1 className="gallery-title">Bharat Mobility 2025</h1>
          <p>
            <i>BYD India</i> held a ground-breaking event in Bharat Mandapam,
            New Delhi.
          </p>
        </div>
        <div className="gallery-image-video">
          <div className="gallery-images">
            {currentItems.map((item, index) => (
              <div className="image" key={index}>
                {item.type === "video" ? (
                  <iframe
                    src={item.url}
                    title={`Media ${index}`}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                    }}
                  />
                ) : (
                  <img src={item.url} alt={`Media ${index}`} />
                )}
              </div>
            ))}
          </div>
          <div className="pagination-buttons">
            <button
              onClick={handlePrev}
              disabled={currentPage === 0}
              aria-label="Previous"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <button
              onClick={handleNext}
              disabled={endIndex >= mediaItems.length}
              aria-label="Next"
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
