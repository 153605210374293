import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import configData from "../../../config.json";

const ContactUsForm = () => {
  const nameRegex = /^[a-zA-Z][a-zA-Z0-9]*(\s+[a-zA-Z][a-zA-Z0-9]*)*$/;
  const mobileRegex = /^[6-9]\d{9}$/;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const modelRef = useRef();
  const [thankyouMSG, setThankyouMSG] = useState(false);
  const [dropDownModel, setDropDownModel] = useState("");
  const [dropDownModelToggle, setDropDownModelToggle] = useState(false);
  const queryType = [
    { label: "Sales", value: "sales_query" },
    { label: "Support", value: "Support" },
    { label: "Dealership/Business", value: "Dealership/Business" },
    { label: "Content Co-Creation", value: "Content Co-Creation" },
  ];

  const [formError, setFormError] = useState({
    name: false,
    contact_number: false,
    email_id: false,
    message: false,
    query_type: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    contact_number: "",
    email_id: "",
    message: "",
    query_type: "",
  });

  const handleInput = (e) => {
    setFormError({ ...formError, [e.target.name]: false });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const toggleModelDropDown = () => {
    setDropDownModelToggle(!dropDownModelToggle);
  };

  const selectModel = (item) => {
    toggleModelDropDown();
    setDropDownModel(item.label);
    setFormData({
      ...formData,
      query_type: item.value,
    });
  };

  const handleClick = (event) => {
    if (modelRef.current && !modelRef.current.contains(event.target)) {
      setDropDownModelToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const sendQuery = () => {
    if (!nameRegex.test(formData.name)) {
      setFormError({ ...formError, name: true });
      return false;
    }
    if (!mobileRegex.test(formData.contact_number)) {
      setFormError({ ...formError, contact_number: true });
      return false;
    }
    if (!emailRegex.test(formData.email_id)) {
      setFormError({ ...formError, email_id: true });
      return false;
    }
    if (!formData.query_type) {
      setFormError({ ...formError, query_type: true });
      return false;
    }
    if (!formData.message) {
      setFormError({ ...formError, message: true });
      return false;
    }

    axios
      .request({
        method: "post",
        url: configData.API_URL + "support-ticket",
        data: formData,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        setFormData({
          name: "",
          contact_number: "",
          email_id: "",
          message: "",
          query_type: "",
        });
        setDropDownModel("");
        setThankyouMSG(true);
        setTimeout(() => {
          setThankyouMSG(false);
        }, 3000);
      })
      .catch((error) => {
        setThankyouMSG(false);
      });
  };

  return (
    <>
      <section className="fluid">
        <div className="container">
          <div className="row pv-30">
            <div className="col-xs-12 col-sm-12 col-md-12 pv-30">
              <p className="heading-3">Write to us</p>
              <p>Please share your contact and we will call you.</p>
              <form className="contact-support">
                <div className="fieldset">
                  <input
                    type="text"
                    value={formData.name}
                    placeholder="Name"
                    name="name"
                    onChange={handleInput}
                    className="mb-2"
                  />
                  {formError.name && (
                    <span className="red">Please enter valid name</span>
                  )}
                </div>
                <div className="fieldset">
                  <input
                    type="text"
                    value={formData.contact_number}
                    placeholder="Contact No."
                    name="contact_number"
                    onChange={handleInput}
                    className="mb-2"
                  />
                  {formError.contact_number && (
                    <span className="red">Please enter valide Contact No.</span>
                  )}
                </div>
                <div className="fieldset">
                  <input
                    type="email"
                    value={formData.email_id}
                    placeholder="Email"
                    name="email_id"
                    onChange={handleInput}
                    className="mb-2"
                  />
                  {formError.email_id && (
                    <span className="red">Please enter Email</span>
                  )}
                </div>
                <div className="fieldset">
                  <div className="customDropDown support fluid" ref={modelRef}>
                    <div
                      className="dropDownLabel"
                      onClick={toggleModelDropDown}
                    >
                      <span>{dropDownModel || "Select query type"}</span>
                      <i className="icon-arrow-down"></i>
                    </div>
                    <ul className={dropDownModelToggle ? "active" : ""}>
                      {queryType.map((item, index) => (
                        <li
                          key={`support_type_${index}`}
                          onClick={() => selectModel(item)}
                        >
                          {item.label}
                        </li>
                      ))}
                      {/* <li data-index="0" keys="byd_atto3" onClick={() => selectModel('sales_query')}>Sales</li>
                                            <li data-index="0" keys="byd_atto3" onClick={() => selectModel('sales_query')}>Support</li>
                                            <li data-index="0" keys="byd_atto3" onClick={() => selectModel('sales_query')}>Dealership/Business</li>
                                            <li data-index="0" keys="all_new_e6" onClick={() => selectModel('custom_query')}>Content Co-Creation</li> */}
                    </ul>
                  </div>
                  {formError.query_type && (
                    <span className="red">Please select Query Type</span>
                  )}
                </div>
                <div className="fieldset textareaWrapper">
                  <textarea
                    rows="2"
                    value={formData.message}
                    placeholder="Type your query here ..."
                    name="message"
                    onChange={handleInput}
                  ></textarea>
                  {formError.message && (
                    <span className="red">Please enter Message</span>
                  )}
                </div>
                <div className="fieldset textareaWrapper text-center">
                  {thankyouMSG && (
                    <div className="green">Your email has been sent!</div>
                  )}
                </div>
                <button className="load-more" type="button" onClick={sendQuery}>
                  SEND
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsForm;
