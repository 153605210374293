import React, { useEffect, useState } from "react";
import axios from "axios";
import dompurify from "dompurify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import configData from "../../config.json";
import Keys from "../../constants/dynamicKeys";

import Header from "../Header";
import Footer from "../Footer";
import SliderWithSingleImage from "../common/sliderWithSingleImage/sliderWithSingleImage";
import SliderWithMultipleImage from "../common/sliderWithMultipleImage/sliderWithMultipleImage";
import SliderWithRightCaption from "../common/sliderWithRightCaption/sliderWithRightCaption";
import SliderWithLeftCaption from "../common/sliderWithLeftCaption/sliderWithLeftCaption";
import SliderWithTopCaption from "../common/sliderWithTopCaption/sliderWithTopCaption";
import HomeSlider from "../common/homeSlider/homeSlider";
import TestDriveForm from "../common/testDriveForm/testDriveForm";
import Newsletter from "../common/newsletter/newsletter";
import ContactUsForm from "../common/contactUsForm/contactUsForm";
import News from "../common/news/news";
import Event from "../common/event/event";
import Dealers from "../common/dealerList/dealerList";
import Career from "../common/career/career";
import Bydsealhomeimage from "../common/bydsealhome/bydsealhomeimage";
import BYDSealSlider from "../common/bydSealSlider/BYDSealSlider";
import BydAttoSlider from "../common/bydAttoSlider/BydAttoSlider";
import BydAttohomeimage from "../common/bydAttohome/BydAttohomeimage";
import BydeMaxHome from "../common/bydeMaxhome/BydeMaxHome";
import BydeMaxSlider from "../common/bydeMaxCarSlider/BydeMaxSlider";
import BydSealionHome from "../common/bydSealionhome/BydSealionHome";
import BydSealion7Slider from "../common/bydSealion7/BydSealion7Slider";
import SocialSlider from "../common/socialSlider/SocialSlider";
import ImageGallery from "../common/ImageGallery/ImageGallery";


const SinglePage = () => {
  const [pageData, setPageData] = useState({});
  const pathArray = window.location.pathname.split("/");
  var bgClass = "common-bg";

  const getBanner = () => {
    let sku = "";
    if (pathArray.length > 2) {
      //
    } else {
      if (pathArray[1] === "") {
        sku = "home";
      } else {
        sku = pathArray[pathArray.length - 1];
      }
    }
    if (pathArray[1] === "thankyou") {
      bgClass = "test-drive-banner";
    }
    axios.get(`${configData.API_URL}page/${sku}`).then(function (response) {
      const body = JSON.parse(response.data.data.body);
      let accord = false;
      let carColors = false;
      if (body.length > 0) {
        body.forEach((elem) => {
          if (elem.content.includes("accord-toggler")) {
            accord = true;
          }
          if (elem.content.includes("newmodel-colors-thumb-img")) {
            carColors = true;
          }
        });
      }
      if (accord) {
        window.checkAccordElement();
      }
      if (carColors) {
        window.changeCarColors();
      }
      setPageData({
        ...response.data.data,
        body: JSON.parse(response.data.data.body),
      });
    });
  };

  useEffect(() => {
    getBanner();
  }, []);

  const getDynamicData = (elem) => {
    switch (elem.contentType) {
      case Keys.HOME_SLIDER:
        return <HomeSlider sliderId={elem.sliderid} />;
      case Keys.SLIDER_WITH_SINGLE_IMAGE:
        return <SliderWithSingleImage sliderId={elem.sliderid} />;
      case Keys.SLIDER_WITH_MULTIPLE_IMAGE:
        return <SliderWithMultipleImage sliderId={elem.sliderid} />;
      case Keys.SLIDER_WITH_TOP_CAPTION:
        return <SliderWithTopCaption sliderId={elem.sliderid} />;
      case Keys.SLIDER_WITH_LEFT_CAPTION:
        return (
          <SliderWithLeftCaption
            sliderId={elem.sliderid}
            leftCaption={elem.content}
            title={elem.sectionTitle}
          />
        );
      case Keys.SLIDER_WITH_RIGHT_CAPTION:
        return (
          <SliderWithRightCaption
            sliderId={elem.sliderid}
            rightCaption={elem.content}
            title={elem.sectionTitle}
          />
        );
      case Keys.TEST_DRIVER_FORM:
        return (
          <TestDriveForm
            backgroundImage={elem.backgroundImage}
            modelIndex={elem.modelIndex}
          />
        );
      case Keys.NEWSLETTER:
        return <Newsletter />;
      case Keys.NEWS:
        return <News />;
      case Keys.EVENT:
        return <Event />;
      case Keys.CONTACTUSFORM:
        return <ContactUsForm />;
      case Keys.DEALERLIST:
        return <Dealers />;
      case Keys.CAREER_LIST:
        return <Career />;
      case Keys.BYDSEALHOMESLIDER_PAGE:
        return <Bydsealhomeimage />;
      case Keys.BYDSEAL_SLIDER:
        return <BYDSealSlider />;
      case Keys.BYDATTO_SLIDER:
        return <BydAttoSlider />;
      case Keys.BYDATTOHOMESLIDER_PAGE:
        return <BydAttohomeimage />;
      case Keys.BYDEMAXHOMESLIDER_PAGE:
        return <BydeMaxHome />;
      case Keys.BYDEMAX_SLIDER:
        return <BydeMaxSlider />;
      case Keys.BYD_SEALION_7_SLIDER:
        return <BydSealion7Slider />;
      case Keys.IMAGE_GALLERY:
        return <ImageGallery />;
      case Keys.SOCIAL_SLIDER:
        return <SocialSlider />;
      case Keys.BYDSEALIONHOMESLIDER_PAGE:
        return <BydSealionHome />;
      default:
        return <>default</>;
    }
  };
  return (
    <React.Fragment>
      <Header />
      {pathArray[1] !== "" && (
        <section className="fluid bg-light-gray">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>{pageData.title || ""}</li>
            </ul>
          </div>
        </section>
      )}
      {/* <section className="fluid" dangerouslySetInnerHTML={{__html: banner.full_description}}>
            </section> */}
      {pageData.body &&
        pageData.body.map((elem, index) => {
          if (elem.type === "static") {
            return (
              <div key={index}>
                <section
                  key={`section_${index}`}
                  className={`fluid ${bgClass} lazy`}
                  style={{
                    backgroundImage: `url(${configData.IMAGE_BASE_URL}${elem.backgroundImage})`,
                  }}
                  dangerouslySetInnerHTML={{ __html: elem.content }}
                ></section>
                {elem.contentCSS && (
                  <HelmetProvider>
                    <Helmet>
                      <style>{elem.contentCSS}</style>
                    </Helmet>
                  </HelmetProvider>
                )}
              </div>
            );
          } else {
            return <>{getDynamicData(elem)}</>;
          }
        })}
      <HelmetProvider>
        <Helmet>
          <title>{pageData.meta_title}</title>
          <meta name="description" content={pageData.meta_description} />
          <meta name="keywords" content={pageData.meta_keywords} />
          <meta name="head_tracking_code" content={pageData.head_tracking_code} />
          {/* CMS Head Tracking Code  */}
          <script>{pageData.head_tracking_code}</script>
        </Helmet>
      </HelmetProvider>
      <Footer />
    </React.Fragment>
  );
};

export default SinglePage;
