import React from "react";
import CountUp from "react-countup";

const BydeMaxHome = () => {
  return (
    <section className="fluid bg-light-gray-1">
      <div id="emax7-banner">
        <div className="box-container">
          <div className="seal-box">
            <div className="box-content">
              <div className="box-countup">
                <CountUp
                  start={0}
                  end={530}
                  duration={5}
                  decimals={0}
                  redraw={true}
                />
                km
              </div>
              <div className="subContent">Top driving Range*</div>
            </div>
          </div>
          <div className="seal-box">
            <div className="box-content">
              <div className="box-countup">
                <CountUp
                  start={0}
                  end={8.6}
                  duration={5}
                  decimals={1}
                  redraw={true}
                />
                s
              </div>
              <div className="subContent">0-100 km/h*</div>
            </div>
          </div>
          <div className="seal-box">
            <div className="box-content">
              <div className="box-countup">
                <CountUp
                  start={0}
                  end={71.8}
                  duration={3}
                  decimals={1}
                  redraw={true}
                />
                kWh
              </div>
              <div className="subContent">Battery capacity*</div>
            </div>
          </div>
          <div className="seal-box">
            <div className="box-content">
              <div className="box-countup">
                <CountUp
                  start={0}
                  end={6}
                  duration={3}
                  decimals={0}
                  redraw={true}
                />
                /
                <CountUp
                  start={0}
                  end={7}
                  duration={3}
                  decimals={0}
                  redraw={true}
                />
              </div>
              <div className="subContent">Seater*</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BydeMaxHome;