import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gtag from "ga-gtag";
import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import "./App.css";
import Newsdetails from "./components/pages/newsdetails";
import SinglePage from "./components/pages/SinglePage";
import ModalComponent from "./components/popmodel/ModalComponent";

import enquireLogo from "../src/assets/imgs/enquire3.png";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      const isModalShown = sessionStorage.getItem("isModalShown");
      if (!isModalShown) {
        setIsModalOpen(true);
        sessionStorage.setItem("isModalShown", "true");
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleWhatappGtag = () => {
    gtag("event", "FloatWhatsapp", {
      section_name: "WhatsappFloat",
    });
  };
  return (
    <>
      {/* <Router basename={"/"}> */}
      <Routes>
        <Route path="/news/:newsurl" element={<Newsdetails />} />
        <Route path="*" element={<SinglePage />} />
      </Routes>
      {location.pathname != "/enquire-now" && (
        <a
          href="https://bydautoindia.com/enquire-now"
          className="floating_button enquireLogobox"
          target="_blank"
          rel="noopener noreferrer"
          // onClick={handleWhatappGtag}
        >
         <img src={enquireLogo} className="enquireLogo whatsapp_float" />

          {/* <button className="floating_button_1">Enquire Now</button> */}
        </a>
      )}
      <a
        href="https://wa.me/917303950845?text=hello"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleWhatappGtag}
      >
        <div className="whatsapp_icon_wrapper">
          <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
        </div>
      </a>
      <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal} />
      {/* </Router> */}
    </>
  );
}
export default App;
